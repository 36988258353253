



























































































































































































































































































































import Vue from 'vue'

// import { slugify } from '@/helpers'
import { checkIsInCart } from '@/mixins/UseCart'
import { mapActions, mapGetters } from 'vuex'
import { Accessors } from 'vue/types/options'
import { isEmptyProducts } from '@/mixins/UseProducts'
import { productSelectWithSupplier } from '@/queries'
import {
	Id,
	Sort,
	Query,
	Product,
	Category,
	// Breadcrumb,
	SupplierProducts,
	Manufacturer,
	TimkenAlias,
	// SortOrder,
} from '@/types'

import {
	queryString,
	changeLocationQuery,
	Pagination,
	createPaginaion,
	omitEmpties,
	selectWith,
} from 'vuelpers'

import { chunk } from 'lodash'
import { manufacturererApi } from '@/api'
import { useSessionStorage } from '@/composables/useSessionStorage'
import { MetaInfo } from 'vue-meta'
import { APP_META } from '@/consts'

export default Vue.extend({
	name: 'SupplierProducts',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `OEM Products | ${APP_META.title}`,
		}
	},
	data() {
		const [sortOrder, syncSortOrder] = useSessionStorage<Sort>(
			'suppliers_sort_order',
			{}
		)
		return {
			sortOrder,
			syncSortOrder,
			vSearch: '',
			chunkSize: 1,
			layoutStyle: 'grid',
			filterIds: [] as Id[],
			supplier: null as Manufacturer | null,
			timkenHeaders: [
				{
					text: 'Id',
					value: 'iTimkenAliasId',
				},
				{
					text: 'Code',
					value: 'vCode',
				},
				{
					text: 'Description',
					value: 'vDescription',
				},
				{
					text: 'Product',
					value: 'idProduct',
				},
				{
					text: 'TimkenCode',
					value: 'vTimkenCode',
				},
			],
		}
	},
	created() {
		const layoutStyle = queryString.get('style')
		if (layoutStyle) this.layoutStyle = layoutStyle
	},
	watch: {
		layoutStyle(v: string) {
			changeLocationQuery(
				queryString.stringify({
					style: v,
				})
			)
		},
		// vSearch(v: string) {
		// 	if (!v) {
		// 		this.onFetchSupplierProducts()
		// 	}
		// },
		vReference: {
			immediate: true,
			async handler(v?: Id) {
				if (!v) return
				manufacturererApi
					.show(v, {
						with: selectWith<Manufacturer>([{ timkenAliases: [] }]),
					})
					.then(([err, res]) => {
						if (err) return
						this.supplier = res
						this.$syncStack({
							path: this.$route.path,
							name: this.supplier?.vName,
						})
					})
				this.getSupplierProducts(
					this.fetchQuery({
						perPage: 25,
						vReference: v,
					})
				)
			},
		},
	},
	computed: {
		...(mapGetters('products', ['$currentSupplierProducts']) as Accessors<{
			$currentSupplierProducts: SupplierProducts
		}>),
		timkenAliases(): TimkenAlias[][] {
			if (!this.supplier) return []
			const timkenAliases = this.supplier.timkenAliases?.filter((ta) => {
				return [ta.vCode, ta.vDescription, ta.iTimkenAliasId].some((v) => {
					return v
						.toString()
						.toLowerCase()
						.includes((this.vSearch || '').toLowerCase())
				})
			})
			return chunk(timkenAliases || [], this.chunkSize)
		},
		productsNotFound(): boolean {
			if (!this.products.data.length) return true
			return isEmptyProducts(this.products)
		},
		query(): Query {
			return {
				with: productSelectWithSupplier,
			}
		},
		products(): Pagination<Product> {
			if (!this.$currentSupplierProducts) return createPaginaion()
			return this.$currentSupplierProducts.products
		},
		slug(): string {
			return this.$route.params.slug
		},
		manufacturer(): Manufacturer | undefined {
			if (!this.products || !this.products.data.length) return undefined
			if (!this.products.data[0].timkenAliases) return undefined
			return this.products.data[0].timkenAliases[0].manufacturer
		},
		category(): Category | undefined {
			if (!this.products || !this.products.data.length) return undefined
			return this.products.data[0].category
		},
		vReference(): string {
			return this.$route.params.id
		},
		btnLayoutStyle: {
			get(): number {
				return this.layoutStyle === 'grid' ? 0 : 1
			},
			set(v: number) {
				this.layoutStyle = v === 0 ? 'grid' : 'list'
			},
		},
	},
	methods: {
		checkIsInCart,
		...mapActions('products', ['getSupplierProducts']),
		onClickTimkenAlias(item: TimkenAlias) {
			if (item.idProduct > 0) {
				this.$router.push(`/products/${item.idProduct}`)
			}
			// Enquiry
			else {
				this.$router.push({
					path: '/enquiries/new',
					query: {
						iTimkenAliasId: item.iTimkenAliasId as string,
					},
				})
			}
		},
		fetchQuery(query: Query = {}): Query {
			return omitEmpties({
				...this.query,
				...this.sortOrder,
				// vSearch: this.vSearch,
				page: this.products.currentPage,
				perPage: this.products.perPage,
				vReference: this.vReference,
				...query,
			})
		},
		onChangePage(page: number) {
			this.getSupplierProducts(
				this.fetchQuery({
					page,
				})
			)
		},
		onChangePerPage(perPage: any) {
			if (!perPage || perPage == this.products.perPage) return
			if (perPage === 'all') perPage = this.products.total
			this.getSupplierProducts(
				this.fetchQuery({
					page: 1,
					perPage: +perPage,
				})
			)
		},
		onFetchSupplierProducts() {
			this.syncSortOrder(this.sortOrder)
			this.getSupplierProducts(this.fetchQuery())
		},
		onClickSearch() {
			if (!this.vSearch) return
			this.getSupplierProducts(
				this.fetchQuery({
					page: 1,
				})
			)
		},
	},
})
