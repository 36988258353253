import { isObject } from 'lodash'
// import { useReactive } from './useReactive'

const useSessionStorage = <T extends Record<string, unknown>>(
	key: string,
	initialValue: T
): [T, (newValue: T) => void] => {
	const sessionValue = sessionStorage.getItem(key)
	const value: T = sessionValue ? JSON.parse(sessionValue) : initialValue

	const onChange = (newValue: T) => {
		const val = isObject(newValue) ? JSON.stringify(newValue) : newValue
		window.sessionStorage.setItem(key, val as string)
	}

	// const v = useReactive<T>(value, onChange)

	return [value, onChange]

	// const setValue = (newValue: T) => {
	// 	const valueToStore = isObject(newValue)
	// 		? JSON.stringify(newValue)
	// 		: newValue
	// 	window.sessionStorage.setItem(key, valueToStore as string)
	// }
	// return [value, setValue]
}

export { useSessionStorage }
